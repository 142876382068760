// import { LayoutBaseDePagina } from "../../shared/layouts";
// import { FerramentasDaListagem } from "../../shared/components/ferramentas-da-listagem/FerramentasDaListagem";

import { AsideMenu } from "../../shared/components";
import { BaseLayout } from "../../shared/layouts";

export const Dashboard: React.FC = () => {
  return (
    <>
      <AsideMenu>
        <BaseLayout>
          <div>test</div>
        </BaseLayout>
      </AsideMenu>
    </>
  );
};
