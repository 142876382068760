import { useEffect, useState } from "react";

import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import { SliderCenter } from "../../../../shared/components";

import backgroundFallGrecin2000 from "../../../../shared/assets/background_fall_grecin2000.svg";
import backgroundFallTonsOfGray from "../../../../shared/assets/background_fall_tons_of_gray.svg";
import backgroundFallShampoo from "../../../../shared/assets/background_fall_shampoo.svg";
import backgroundFallBeard from "../../../../shared/assets/background_fall_beard.svg";

interface IProductSliderProps {
  imageList: string[];
  productId: number;
}

const grecin2000Configs = {
  divider_color: "#B4C6E7",
  svg_image: backgroundFallGrecin2000,
};

const grecinTonsOfGrayConfigs = {
  divider_color: "#3F72B2",
  svg_image: backgroundFallTonsOfGray,
};

const shampooConfigs = {
  divider_color: "#E1433A",
  svg_image: backgroundFallShampoo,
};

const beardConfigs = {
  divider_color: "#0ba7bc",
  svg_image: backgroundFallBeard,
};

export const ProductSlider: React.FC<IProductSliderProps> = ({
  imageList,
  productId,
}) => {
  const theme = useTheme();
  const { breakpoints } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));

  const [cardConfigs, setCardConfigs] = useState<any>();

  const handleCardOptions = (id: number) => {
    switch (id) {
      case 1012:
        setCardConfigs(grecin2000Configs);
        break;

      case 1050:
        setCardConfigs(grecinTonsOfGrayConfigs);
        break;

      case 1111:
      case 1112:
      case 1113:
      case 1114:
      case 1116:
        setCardConfigs(shampooConfigs);
        break;

      case 3130:
      case 3131:
      case 3132:
      case 3133:
        setCardConfigs(beardConfigs);
        break;

      default:
        setCardConfigs(beardConfigs);
        break;
    }
  };

  useEffect(() => {
    handleCardOptions(productId);
  }, [productId]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          background: `url(${cardConfigs?.svg_image}) no-repeat bottom`,
          backgroundSize: "cover",
          height: 460,
          maxHeight: 460,
        }}
      >
        <Box paddingTop={smDown ? 13 : 9}>
          <SliderCenter
            slides={imageList ?? []}
            color={cardConfigs?.divider_color}
          />
        </Box>
      </Box>
      <Divider
        sx={{ background: cardConfigs?.divider_color, height: "16px" }}
      />
    </>
  );
};
