/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { useDrawerContext } from "../shared/contexts";
import { Dashboard, Products } from "../pages";
import { ProductsView } from "../pages/new-product-view/products";

export const AppRoutes = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
    setDrawerOptions([
      {
        label: "Pagina inicial",
        icon: "home",
        path: "/home",
      },
      {
        label: "Product",
        icon: "category",
        path: "/grecin2000",
      },
      {
        label: "Product",
        icon: "category",
        path: "/tonsdegrisalho",
      },
      {
        label: "Product",
        icon: "category",
        path: "/shampoocastescuro",
      },
      {
        label: "Product",
        icon: "category",
        path: "/shampoocastanhoclaro",
      },
      {
        label: "Product",
        icon: "category",
        path: "/shampoocastanho",
      },
      {
        label: "Product",
        icon: "category",
        path: "/shampoocastescuro",
      },
      {
        label: "Product",
        icon: "category",
        path: "/shampoopreto",
      },
      {
        label: "Product",
        icon: "category",
        path: "/shampoocastmedescuro",
      },
      {
        label: "Product",
        icon: "category",
        path: "/bebcastclaro",
      },
      {
        label: "Product",
        icon: "category",
        path: "/bebcastanho",
      },
      {
        label: "Product",
        icon: "category",
        path: "/bebcastescuro",
      },
      {
        label: "Product",
        icon: "category",
        path: "/bebpreto",
      },
    ]);
  }, []);

  return (
    <Routes>
      <Route path="/home" element={<Dashboard />} />
      <Route path="/:path" element={<ProductsView />} />
      {/* <Route path="old/:path" element={<Products />} /> */}

      <Route path="*" element={<Navigate to="/grecin2000" />} />
    </Routes>
  );
};
