import { useDrawerContext } from "./../contexts/drawerContext";
import {
  Box,
  Icon,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

interface ILayoutProps {
  children: React.ReactNode;
  title?: string;
}

export const BaseLayout: React.FC<ILayoutProps> = ({ children, title }) => {
  const theme = useTheme();
  const { spacing } = theme;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { toggleDrawerOpen } = useDrawerContext();

  return (
    <Box height="100%" overflow='hidden'>
      {title && (
        <Box
          p={1}
          height={spacing(smDown ? 6 : mdDown ? 8 : 12)}
          display="flex"
          alignItems="center"
          gap={1}
        >
          {smDown && (
            <IconButton onClick={toggleDrawerOpen}>
              <Icon>menu</Icon>
            </IconButton>
          )}

          <Typography
            variant={smDown ? "h5" : mdDown ? "h4" : "h3"}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {title}
          </Typography>
        </Box>
      )}
      <Box flex={1} overflow="auto">
        {children}
      </Box>
    </Box>
  );
};
