import { Box, useMediaQuery, useTheme } from "@mui/material";

import logoGrecin from "../../assets/grecin_logo.png";

interface IMenuProps {
  children?: React.ReactNode;
}

export const HeaderMenu: React.FC<IMenuProps> = ({ children }) => {
  const theme = useTheme();
  const { spacing, breakpoints, palette } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Box
          sx={{ background: palette.primary.main }}
          width="100%"
          height={spacing(4)}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* <Box
            sx={{
              maxWidth: "1460px",
              width: "100%",
              paddingX: smDown ? spacing(4) : spacing(8),
            }}
            display="flex"
            justifyContent="end"
          >
            <Link
              href="https://www.justformen.com/pt-br/how-it-works"
              underline="none"
              variant="body2"
              color="white"
              sx={{ fontWeight: 700 }}
              target="_blank"
            >
              COMO FUNCIONA
            </Link>
          </Box> */}
        </Box>

        <Box
          width="100%"
          height={spacing(8)}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            boxShadow: "0 0.125rem 0.75rem rgba(0,0,0,.1)",
            background: "white",
          }}
        >
          <Box
            sx={{
              maxWidth: "1460px",
              width: "100%",
              paddingX: smDown ? spacing(4) : spacing(8),
            }}
            display="flex"
          >
            <img
              src={logoGrecin}
              alt="Logo Grecin"
              style={{ objectFit: "cover", width: smDown ? "90px" : "60px" }}
            />
            {/* <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              ml={spacing(2)}
              gap={spacing(2)}
            >
              <Link
                href="#"
                underline="none"
                variant="body2"
                color="black"
                sx={{ fontWeight: 700 }}
              >
                teste
              </Link>
              <Link
                href="#"
                underline="none"
                variant="body2"
                color="black"
                sx={{ fontWeight: 700 }}
              >
                teste
              </Link>
            </Box> */}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        width="100vw"
        flexDirection="column"
      >
        {children}
      </Box>
    </Box>
  );
};
