import React, { useMemo } from 'react';
import { Button, Icon } from "@mui/material";
import { useLocation } from "react-router-dom";
import { handleDarkColor } from "../../utils";
import { getDownloadFileByRoute } from '../../utils/getDownloadFileByRoute';

interface IDownloadButtonProps {
  color?: string;
}

export const DownloadButton: React.FC<IDownloadButtonProps> = ({ color }) => {
  const { pathname } = useLocation();

  const pdf = useMemo(() => {
    return getDownloadFileByRoute(pathname);
  }, [pathname]);

  const handleOpen = () => {
    if (pdf) {
      window.open(pdf.fileUrl, '_blank');
    }
  };

  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        borderRadius: "10px",
        fontSize: 12,
        background: color ?? "#292929",
        "&:hover": {
          backgroundColor: color ? handleDarkColor(color, 20) : "#1c1c1c", 
        },
      }}
      endIcon={<Icon sx={{ fontSize: 12 }}>file_download</Icon>}
      onClick={handleOpen}
    >
      Instruções de uso
    </Button>
  );
};
