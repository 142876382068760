import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

import beard_card_1 from "../../../../shared/assets/cards/beard_card_1.png";
import beard_card_2 from "../../../../shared/assets/cards/beard_card_2.png";
import beard_card_3 from "../../../../shared/assets/cards/beard_card_3.png";
import beard_card_4 from "../../../../shared/assets/cards/beard_card_4.png";

import hair_card_1 from "../../../../shared/assets/cards/hair_card_1.png";
import hair_card_2 from "../../../../shared/assets/cards/hair_card_2.png";
import hair_card_3 from "../../../../shared/assets/cards/hair_card_3.png";
import hair_card_4 from "../../../../shared/assets/cards/hair_card_4.png";

import runTime from "../../../../shared/assets/icons-svg/run_time.svg";
import selfCare from "../../../../shared/assets/icons-svg/self_care.svg";
import smile from "../../../../shared/assets/icons-svg/smile.svg";
import update from "../../../../shared/assets/icons-svg/update.svg";

import "./styles.css";
import { useEffect, useState } from "react";

interface IContentCardProps {
  productId: number;
  color?: string;
}

type ArrayBox = {
  title: string;
  description: string;
  icon: string;
  bgImage: string;
};

const arrayShadesOfGray = [
  {
    bgImage: hair_card_1,
    icon: runTime,
    title: "Mistura instantãnea",
    description: "Uniformiza o grisalho no 1° uso",
  },
  {
    bgImage: hair_card_2,
    icon: selfCare,
    title: "Aparência natural",
    description: "Tonaliza o fio branco, mas não todos",
  },
  {
    bgImage: hair_card_3,
    icon: smile,
    title: "Apenas penteie",
    description: "Aplique nas áreas mais grisalhas ou penteie todo o cabelo",
  },
  {
    bgImage: hair_card_4,
    icon: update,
    title: "Fórmula em gel",
    description: "Fórmula livre de amônia e peróxido",
  },
];

const arrayBeard = [
  {
    bgImage: beard_card_1,
    icon: runTime,
    title: "Rápido e fácil",
    description: "Elimina instantaneamente os pelos brancos em 5 minutos",
  },
  {
    bgImage: beard_card_2,
    icon: selfCare,
    title: "Simples como pentear a barba",
    description: "Com pincel aplicador",
  },
  {
    bgImage: beard_card_3,
    icon: smile,
    title: "Aparência natural",
    description: "Desenvolvido para tonalizar de acordo com cor natural do fio",
  },
  {
    bgImage: beard_card_4,
    icon: update,
    title: "Longa duração",
    description:
      "Não desbota com a lavagem. Dura até que o grisalho volte a crescer",
  },
];

const arrayHair = [
  {
    bgImage: hair_card_1,
    icon: runTime,
    title: "Mais tonalidades",
    description:
      "Desenvolvido para tonalizar de acordo com a cor natural dos seus cabelos",
  },
  {
    bgImage: hair_card_2,
    icon: selfCare,
    title: "O mais rápido",
    description: "Elimina instantãneamente o cinza em 5 minutos",
  },
  {
    bgImage: hair_card_3,
    icon: smile,
    title: "De longa duração",
    description: "Dura até 8 semanas até que o grisalho volte a crescer",
  },
  {
    bgImage: hair_card_4,
    icon: update,
    title: "Qualidade",
    description: "100% de satisfação garantida",
  },
];

const grecin2000Configs = {
  color: "#B4C6E7",
};

const grecinTonsOfGrayConfigs = {
  color: "#3F72B2",
};

const shampooConfigs = {
  color: "#E1433A",
};

const beardConfigs = {
  color: "#0ba7bc",
};

export const ContentCard: React.FC<IContentCardProps> = ({
  productId,
  color,
}) => {
  const [arrayBox, setArrayBox] = useState<Array<ArrayBox>>([]);
  const [cardConfigs, setCardConfigs] = useState<any>();

  const handleArrayBox = () => {
    if ([1050].includes(productId)) {
      setArrayBox(arrayShadesOfGray);
      setCardConfigs(grecinTonsOfGrayConfigs);
    } else if ([1111, 1112, 1113, 1114, 1116].includes(productId)) {
      setArrayBox(arrayHair);
      setCardConfigs(shampooConfigs);
    } else {
      setArrayBox(arrayBeard);
      setCardConfigs(beardConfigs);
    }
  };

  useEffect(() => {
    handleArrayBox();
  }, [productId]);

  return (
    <>
      {productId !== 1012 && (
        <Box>
          <Box px={2} mb={4}>
            <Typography
              variant="h4"
              align="center"
              fontFamily="Addington CF"
              fontWeight={700}
            >
              {productId !== 1050
                ? "Desperte sua melhor versão"
                : "Instantaneamente mescla os fios brancos e escuros"}
            </Typography>
          </Box>
          <Box px={2}>
            <Grid container spacing={3} height="100%">
              {arrayBox.map((v, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={3}
                  style={{ display: "flex", position: "relative" }}
                >
                  <Card className="card">
                    <Box sx={{ position: "relative" }}>
                      <CardMedia
                        component="img"
                        image={v.bgImage}
                        alt="Imagem do card"
                        sx={{ filter: "brightness(50%)" }}
                      />
                      <Box className="iconCard" aria-label="Ícone de tempo">
                        <img height={80} width={80} src={v.icon} alt="" />
                      </Box>
                    </Box>
                    <CardContent>
                      <Typography
                        align="center"
                        variant="h5"
                        component="div"
                        color={cardConfigs.color ?? "#00859A"}
                        fontFamily="Addington CF"
                        fontWeight={700}
                      >
                        {v.title}
                      </Typography>
                      <Typography align="center" variant="body2" color="text">
                        {v.description}
                      </Typography>
                    </CardContent>

                    <Box className="lineBoxContainer">
                      <Box
                        className="lineBoxItem"
                        style={{
                          border: `1px solid ${cardConfigs.color ?? "#00859A"}`,
                        }}
                      />
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};
