import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./styles.css";

import castanho_claro from "../../../../shared/assets/products/castanho_claro.png";
import castanho from "../../../../shared/assets/products/castanho.png";
import castanho_medio_escuro from "../../../../shared/assets/products/castanho_medio_escuro.png";
import castanho_escuro from "../../../../shared/assets/products/castanho_escuro.png";
import preto from "../../../../shared/assets/products/preto.png";
interface IProductColorProps {
  productId: number;
}

const beardColors = [
  {
    id: 3130,
    name: "Castanho claro",
    code: "M-80",
    color: castanho_claro,
    path: "bebcastclaro",
    selected_color: "#00859a",
  },
  {
    id: 3131,
    name: "Castanho",
    code: "M-90",
    color: castanho,
    path: "bebcastanho",
    selected_color: "#00859a",
  },
  {
    id: 3132,
    name: "Castanho Escuro",
    code: "M-100",
    color: castanho_escuro,
    path: "bebcastescuro",
    selected_color: "#00859a",
  },
  {
    id: 3133,
    name: "Preto",
    code: "M-110",
    color: preto,
    path: "bebpreto",
    selected_color: "#00859a",
  },
];

const hairColors = [
  {
    id: 1111,
    name: "Castanho claro",
    code: "M-25",
    color: castanho_claro,
    path: "shampoocastanhoclaro",
    selected_color: "#BF1D3E",
  },
  {
    id: 1112,
    name: "Castanho",
    code: "M-35",
    color: castanho,
    path: "shampoocastanho",
    selected_color: "#BF1D3E",
  },
  {
    id: 1116,
    name: "Castanho médio escuro",
    code: "M-40",
    color: castanho_medio_escuro,
    path: "shampoocastmedescuro",
    selected_color: "#BF1D3E",
  },
  {
    id: 1113,
    name: "Castanho Escuro",
    code: "M-45",
    color: castanho_escuro,
    path: "shampoocastescuro",
    selected_color: "#BF1D3E",
  },
  {
    id: 1114,
    name: "Preto",
    code: "M-55",
    color: preto,
    path: "shampoopreto",
    selected_color: "#BF1D3E",
  },
];

export const ProductColor: React.FC<IProductColorProps> = ({ productId }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { breakpoints } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));
  const [selectedArray, setSelectedArray] = useState<any>([]);

  const handleSelect = (value: any) => {
    navigate(`/${value.path}`);
  };

  useEffect(() => {
    if ([3130, 3131, 3132, 3133].includes(productId))
      setSelectedArray(beardColors);
    else setSelectedArray(hairColors);
  }, [productId]);

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection={smDown ? "row" : "column"}
        justifyContent="center"
        flexWrap="wrap"
      >
        {selectedArray.map((v: any, index: number) => (
          <Box
            key={v.id}
            display="flex"
            alignItems="center"
            className={productId === v.id ? "colorBoxSelected" : "colorBox"}
            onClick={() => handleSelect(v)}
          >
            <img
              src={v.color}
              className={productId === v.id ? "colorImgSelected" : "colorImg"}
              style={{
                border: `${
                  productId === v.id
                    ? `4px solid ${v.selected_color}`
                    : "0px solid #00859a"
                }`,
              }}
            />
            {!smDown && (
              <Box>
                <Typography
                  className={
                    productId === v.id
                      ? "colorTextNameSelected"
                      : "colorTextName"
                  }
                >
                  {v.name}
                </Typography>
                <Typography
                  className={
                    productId === v.id
                      ? "colorTextCodeSelected"
                      : "colorTextCode"
                  }
                >
                  {v.code}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};
