import code1012 from "../../assets/products/1012_GRECIAN_LOTION_LEFT.png";
import code1050 from "../../assets/products/1050_TOG_34RT.png";
import code1050_2 from "../../assets/products/1050_II_TOG_TOP.png";
import code1111 from "../../assets/products/1111_ShampoColorH25_34RT.jpg";
import code1111_2 from "../../assets/products/1111_ShampoColorH25_TOP.jpg";
import code1112 from "../../assets/products/1112_Shampo ColorH35_34RT.jpg";
import code1112_2 from "../../assets/products/1112_Shampocolor_TOP.png";
import code1113 from "../../assets/products/1113_ShampoColor_H45_34RT.jpg";
import code1113_2 from "../../assets/products/1113_ShampoColor_TOP.jpg";
import code1114 from "../../assets/products/1114_ShampooColor_H55_34RT.jpg";
import code1114_2 from "../../assets/products/1114_ShampooColorH55_TOP.png";
import code1116 from "../../assets/products/1116_ShampoColor_H40_34RT.jpg";
import code1116_2 from "../../assets/products/1116_ShampoColor_H40_TOP.jpg";
import code3130 from "../../assets/products/3130_Barba e bigode_34RT.jpg";
import code3130_2 from "../../assets/products/3130_II_Barba e bigode_TOP.jpg";
import code3131 from "../../assets/products/3131_Barba e bigode_34RT.jpg";
import code3131_2 from "../../assets/products/3131_Barbaebigode_TOP.jpg";
import code3132 from "../../assets/products/3132_Barba e Bigode_34RT.jpg";
import code3132_2 from "../../assets/products/3132_Barba e Bigode_TOP.png";
import code3133 from "../../assets/products/3133_Barba e Bigode_34RT.jpg";
import code3133_2 from "../../assets/products/3133_Barba e Bigode_TOP.png";

import code1111_feature from "../../assets/products/featured-images/1111_ShampoColorH25_34RT_featured.png";
import code1112_feature from "../../assets/products/featured-images/1112_Shampo_ColorH35_34RT_featured.png";
import code1113_feature from "../../assets/products/featured-images/1113_ShampoColor_H45_34RT_featured.png";
import code1114_feature from "../../assets/products/featured-images/1114_ShampooColor_H55_34RT_featured.png";
import code1116_feature from "../../assets/products/featured-images/1116_ShampoColor_H40_34RT_featured.png";
import code3130_feature from "../../assets/products/featured-images/3130_Barba_e_bigode_34RT_featured.png";
import code3131_feature from "../../assets/products/featured-images/3131_Barba_e_bigode_34RT_featured.png";
import code3132_feature from "../../assets/products/featured-images/3132_Barba_e_Bigode_34RT_featured.png";
import code3133_feature from "../../assets/products/featured-images/3133_Barba_e_Bigode_34RT_featured.png";

export const ProductsMock = [
  {
    id: 1012,
    images: [code1012],
    featured_image: code1012,
    title: "Grecin 2000 ® /n Loção Progressiva",
    subtitle: "Recupera gradualmente a cor natural dos cabelos",
    composition:
      "ÁGUA, PROPILENOGLICOL, ÁLCOOL ETÍLICO, ENXOFRE, ACETATO DE CHUMBO, CLORETO DE AMÔNIO, PANTENOL, ÁCIDO ACÉTICO, OCTOXINOL, PERFUME, BENZOATO DE DENATÔNIO, ALFA-ISOMETIL IONONA, BENZOATO DE BENZILA, LIMONENO, LINALOL.",
    path: "grecin2000",
    categories: ["Loção gradual", "Para todas as cores de cabelo"],
  },
  {
    id: 1050,
    images: [code1050, code1050_2],
    featured_image: code1050,
    title: "Tons de Grisalho®",
    subtitle: "Instantaneamente mescla os fios brancos e escuros",
    composition:
      "ÁGUA, AMINOMETILPROPANOL, COCO-GLICOSÍDEO, ÁLCOOL ISOPROPÍLICO, ACETATO DE ISOPROPILA, CARBÔMER, 1,2,4-TRI-HIDROXIBENZENO, PERFUME, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, EDETATO TRISSÓDICO, p-AMINOFENOL, ÁCIDO ERITÓRBICO, SULFITO DE SÓDIO, 2-METIL-5-HIDROXIETILAMINOFENOL, ÁCIDO SULFÚRICO, CLORETO DE CINAMIDOPROPILTRIMÔNIO.",
    path: "tonsdegrisalho",
    categories: [
      "Mistura instantânea",
      "1 único tom para todos os tipos de grisalhos",
    ],
    video: "https://www.youtube.com/watch?v=Dgrp5hYzHpM&t=2s",
    videoEmbed: "https://www.youtube.com/embed/Dgrp5hYzHpM",
  },
  {
    id: 1111,
    images: [code1111, code1111_2],
    featured_image: code1111_feature,
    title: "Shampoo color /n Castanho claro | H-25",
    subtitle: "100% de cobertura do grisalho. 100% fácil.",
    composition:
      "ÁGUA, ETANOLAMINA, PERFUME, ÁLCOOL CETOESTEARÍLICO, ÉTER DE MACROGOL MONOESTEARÍLICO, ÁLCOOL OLEÍLICO, RESORCINA, p-FENILENODIAMINA, QUERATINA, ÓLEO DE OLIVA, ACETATO DE TOCOFERILA, PERFUME, PETROLATO AMARELO, SULFITO DE SÓDIO, ÁCIDO ERITÓRBICO, p-AMINOFENOL, m-AMINOFENOL, CETEARIL GLICOSÍDEO, DICETIL FOSFATO, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁLCOOL CETÍLICO, QUERATINA HIDROLISADA, ÁCIDO ETIDRÔNICO, CLORIDRATO DE 2,4-DIAMINOFENOXIETANOL.",
    activator_composition:
      "ÁGUA, PERÓXIDO DE HIDROGÊNIO, ÁLCOOL CETOESTEARÍLICO, LAUROMACROGOL 400, CETOMACROGOL 1000, CROSPOLÍMERO DE ACRILATOS/METACRILATO DE PEG-20 ÉTER DE ÁLCOOL ESTEARÍLICO, ÉTER DE MACROGOL MONOESTEARÍLICO, ÁCIDO ETIDRÔNICO, EDETATO DISSÓDICO, ESTANATO DE SÓDIO.",
    path: "shampoocastanhoclaro",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=fomwz-itL1A&t=1s",
    videoEmbed: "https://www.youtube.com/embed/fomwz-itL1A",
  },
  {
    id: 1112,
    images: [code1112, code1112_2],
    featured_image: code1112_feature,
    title: "Shampoo color /n Castanho | H-35",
    subtitle: "100% de cobertura do grisalho. 100% fácil.",
    composition:
      "ÁGUA, ETANOLAMINA, ÁLCOOL CETOESTEARÍLICO, RESORCINA, ÉTER DE MACROGOL MONOESTEARÍLICO, p-FENILENODIAMINA, ÁLCOOL OLEÍLICO, QUERATINA, ÓLEO DE OLIVA, ACETATO DE TOCOFERILA, PERFUME, PETROLATO AMARELO, SULFITO DE SÓDIO, p-AMINOFENOL, ÁCIDO ERITÓRBICO, m-AMINOFENOL, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, CETEARIL GLICOSÍDEO, DICETIL FOSFATO, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁLCOOL CETÍLICO, QUERATINA HIDROLISADA, CLORIDRATO DE 2,4-DIAMINOFENOXIETANOL, ÁCIDO ETIDRÔNICO.",
    activator_composition:
      "ÁGUA, PERÓXIDO DE HIDROGÊNIO, ÁLCOOL CETOESTEARÍLICO, LAUROMACROGOL 400, CETOMACROGOL 1000, CROSPOLÍMERO DE ACRILATOS/METACRILATO DE PEG-20 ÉTER DE ÁLCOOL ESTEARÍLICO, ÉTER DE MACROGOL MONOESTEARÍLICO, ÁCIDO ETIDRÔNICO, EDETATO DISSÓDICO, ESTANATO DE SÓDIO.",
    path: "shampoocastanho",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=fomwz-itL1A&t=1s",
    videoEmbed: "https://www.youtube.com/embed/fomwz-itL1A",
  },
  {
    id: 1113,
    images: [code1113, code1113_2],
    featured_image: code1113_feature,
    title: "Shampoo color /n Castanho escuro | H-45",
    subtitle: "100% de cobertura do grisalho. 100% fácil.",
    composition:
      "ÁGUA, ETANOLAMINA, p-FENILENODIAMINA, RESORCINA, ÁLCOOL CETOESTEARÍLICO, m-AMINOFENOL, ÉTER DE MACROGOL MONOESTEARÍLICO, ÁLCOOL OLEÍLICO, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, QUERATINA, ÓLEO DE OLIVA, ACETATO DE TOCOFERILA, PERFUME, PETROLATO AMARELO, SULFITO DE SÓDIO, ÁCIDO ERITÓRBICO, p-AMINOFENOL, CETEARIL GLICOSÍDEO, DICETIL FOSFATO, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁLCOOL CETÍLICO, QUERATINA HIDROLISADA, CLORIDRATO DE 2,4-DIAMINOFENOXIETANOL, ÁCIDO ETIDRÔNICO.",
    activator_composition:
      "ÁGUA, PERÓXIDO DE HIDROGÊNIO, ÁLCOOL CETOESTEARÍLICO, LAUROMACROGOL 400, CETOMACROGOL 1000, CROSPOLÍMERO DE ACRILATOS/METACRILATO DE PEG-20 ÉTER DE ÁLCOOL ESTEARÍLICO, ÉTER DE MACROGOL MONOESTEARÍLICO, ÁCIDO ETIDRÔNICO, EDETATO DISSÓDICO, ESTANATO DE SÓDIO.",
    path: "shampoocastescuro",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=fomwz-itL1A&t=1s",
    videoEmbed: "https://www.youtube.com/embed/fomwz-itL1A",
  },
  {
    id: 1114,
    images: [code1114, code1114_2],
    featured_image: code1114_feature,
    title: "Shampoo color /n Preto | H-55",
    subtitle: "100% de cobertura do grisalho. 100% fácil.",
    composition:
      "ÁGUA, ETANOLAMINA, p-FENILENODIAMINA, RESORCINA, m-AMINOFENOL, ÁLCOOL CETOESTEARÍLICO, ÉTER DE MACROGOL MONOESTEARÍLICO, ÁLCOOL OLEÍLICO, SULFATO DE 2-AMINO-4-HIDROXIETILAMINOANISOL, QUERATINA, ÓLEO DE OLIVA, ACETATO DE TOCOFERILA, PERFUME, PETROLATO AMARELO, SULFITO DE SÓDIO, ÁCIDO ERITÓRBICO, CETEARIL GLICOSÍDEO, DICETIL FOSFATO, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁLCOOL CETÍLICO, QUERATINA HIDROLISADA, ÁCIDO ETIDRÔNICO.",
    activator_composition:
      "ÁGUA, PERÓXIDO DE HIDROGÊNIO, ÁLCOOL CETOESTEARÍLICO, LAUROMACROGOL 400, CETOMACROGOL 1000, CROSPOLÍMERO DE ACRILATOS/METACRILATO DE PEG-20 ÉTER DE ÁLCOOL ESTEARÍLICO, ÉTER DE MACROGOL MONOESTEARÍLICO, ÁCIDO ETIDRÔNICO, EDETATO DISSÓDICO, ESTANATO DE SÓDIO.",
    path: "shampoopreto",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=fomwz-itL1A&t=1s",
    videoEmbed: "https://www.youtube.com/embed/fomwz-itL1A",
  },
  {
    id: 1116,
    images: [code1116, code1116_2],
    featured_image: code1116_feature,
    title: "Shampoo color /n Castanho médio escuro | H-40",
    subtitle: "100% de cobertura do grisalho. 100% fácil.",
    composition:
      "ÁGUA, ETANOLAMINA, ÁLCOOL CETOESTEARÍLICO, p-FENILENODIAMINA, RESORCINA, ÉTER DE MACROGOL MONOESTEARÍLICO, m-AMINOFENOL, ÁLCOOL OLEÍLICO, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, QUERATINA, ÓLEO DE OLIVA, ACETATO DE TOCOFERILA, PERFUME, PETROLATO AMARELO, SULFITO DE SÓDIO, ÁCIDO ERITÓRBICO, p-AMINOFENOL, CETEARIL GLICOSÍDEO, DICETIL FOSFATO, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁLCOOL CETÍLICO, QUERATINA HIDROLISADA, CLORIDRATO DE 2,4-DIAMINOFENOXIETANOL, ÁCIDO ETIDRÔNICO.",
    activator_composition:
      "ÁGUA, PERÓXIDO DE HIDROGÊNIO, ÁLCOOL CETOESTEARÍLICO, LAUROMACROGOL 400, CETOMACROGOL 1000, CROSPOLÍMERO DE ACRILATOS/METACRILATO DE PEG-20 ÉTER DE ÁLCOOL ESTEARÍLICO, ÉTER DE MACROGOL MONOESTEARÍLICO, ÁCIDO ETIDRÔNICO, EDETATO DISSÓDICO, ESTANATO DE SÓDIO.",
    path: "shampoocastmedescuro",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=fomwz-itL1A&t=1s",
    videoEmbed: "https://www.youtube.com/embed/fomwz-itL1A",
  },
  {
    id: 3130,
    images: [code3130, code3130_2],
    featured_image: code3130_feature,
    title: "Barba e Bigode /n Castanho Claro | M-80",
    subtitle:
      "Elimina os fios brancos, deixando uma aparência natural e volumosa",
    composition:
      "ÁGUA, AMINOMETILPROPANOL, CARBÔMER, ÁLCOOL CETOESTEARÍLICO, PERFUME, SULFITO DE SÓDIO, RESORCINA, p-FENILENODIAMINA, ÁCIDO ERITÓRBICO, MARROM CARAMELO 150A, BIOTINA, COCO-GLICOSÍDEO, FARINHA DE AVEIA COLOIDAL, EXTRATO DA FOLHA DE ALOE BARBADENSIS, p-AMINOFENOL, m-AMINOFENOL, CETEARIL GLICOSÍDEO, ÓLEO DE COCO, DICETIL FOSFATO, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁCIDO ETIDRÔNICO, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, MALTODEXTRINA.",
    activator_composition:
      "ÁGUA, ÁLCOOL CETÍLICO, PERÓXIDO DE HIDROGÊNIO, MONOESTEARATO DE GLICERILA, ESTEARATO DE MACROGOL 100, ÁCIDO ETIDRÔNICO.",
    path: "bebcastclaro",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=20bnZ0EPffA",
    videoEmbed: "https://www.youtube.com/embed/20bnZ0EPffA",
  },
  {
    id: 3131,
    images: [code3131, code3131_2],
    featured_image: code3131_feature,
    title: "Barba e Bigode /n Castanho | M-90",
    subtitle:
      "Elimina os fios brancos, deixando uma aparência natural e volumosa",
    composition:
      "ÁGUA, AMINOMETILPROPANOL, CARBÔMER, RESORCINA, ÁLCOOL CETOESTEARÍLICO, PERFUME, p-FENILENODIAMINA, SULFITO DE SÓDIO, ÁCIDO ERITÓRBICO, MARROM CARAMELO 150A, BIOTINA, m-AMINOFENOL, COCO-GLICOSÍDEO, FARINHA DE AVEIA COLOIDAL, EXTRATO DA FOLHA DE ALOE BARBADENSIS, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, CETEARIL GLICOSÍDEO, ÓLEO DE COCO, DICETIL FOSFATO, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁCIDO ETIDRÔNICO, MALTODEXTRINA.",
    activator_composition:
      "ÁGUA, ÁLCOOL CETÍLICO, PERÓXIDO DE HIDROGÊNIO, MONOESTEARATO DE GLICERILA, ESTEARATO DE MACROGOL 100, ÁCIDO ETIDRÔNICO.",
    path: "bebcastanho",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=20bnZ0EPffA",
    videoEmbed: "https://www.youtube.com/embed/20bnZ0EPffA",
  },
  {
    id: 3132,
    images: [code3132, code3132_2],
    featured_image: code3132_feature,
    title: "Barba e Bigode /n Castanho escuro | M-100",
    subtitle:
      "Elimina os fios brancos, deixando uma aparência natural e volumosa",
    composition:
      "ÁGUA, AMINOMETILPROPANOL, p-FENILENODIAMINA, RESORCINA, CARBÔMER, ÁLCOOL CETOESTEARÍLICO, PERFUME, m-AMINOFENOL, SULFITO DE SÓDIO, ÁCIDO ERITÓRBICO, MARROM CARAMELO 150A, BIOTINA, COCO-GLICOSÍDEO, FARINHA DE AVEIA COLOIDAL, EXTRATO DA FOLHA DE ALOE BARBADENSIS, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, p-AMINOFENOL, CETEARIL GLICOSÍDEO, ÓLEO DE COCO, DICETIL FOSFATO, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁCIDO ETIDRÔNICO, SULFATO DE 2-AMINO-4-HIDROXIETILAMINOANISOL, MALTODEXTRINA.",
    activator_composition:
      "ÁGUA, ÁLCOOL CETÍLICO, PERÓXIDO DE HIDROGÊNIO, MONOESTEARATO DE GLICERILA, ESTEARATO DE MACROGOL 100, ÁCIDO ETIDRÔNICO.",
    path: "bebcastescuro",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=20bnZ0EPffA",
    videoEmbed: "https://www.youtube.com/embed/20bnZ0EPffA",
  },
  {
    id: 3133,
    images: [code3133, code3133_2],
    featured_image: code3133_feature,
    title: "Barba e Bigode /n Preto | M-110",
    subtitle:
      "Elimina os fios brancos, deixando uma aparência natural e volumosa",
    composition:
      "ÁGUA, p-FENILENODIAMINA, AMINOMETILPROPANOL, RESORCINA, m-AMINOFENOL, CARBÔMER, ÁLCOOL CETOESTEARÍLICO, PERFUME, CLORIDRATO DE 2,4-DIAMINOFENOXIETANOL, SULFATO DE N,N-BIS(2-HIDROXIETIL)-p-FENILENODIAMINA, SULFITO DE SÓDIO, ÁCIDO ERITÓRBICO, p-AMINOFENOL, MARROM CARAMELO 150A, BIOTINA, COCO-GLICOSÍDEO, FARINHA DE AVEIA COLOIDAL, EXTRATO DA FOLHA DE ALOE BARBADENSIS, CETEARIL GLICOSÍDEO, ÓLEO DE COCO, DICETIL FOSFATO, FOSFATO DE PEG-10 ÉTER DE ÁLCOOL CETÍLICO, ÁCIDO ETIDRÔNICO, MALTODEXTRINA.",
    activator_composition:
      "ÁGUA, ÁLCOOL CETÍLICO, PERÓXIDO DE HIDROGÊNIO, MONOESTEARATO DE GLICERILA, ESTEARATO DE MACROGOL 100, ÁCIDO ETIDRÔNICO.",
    path: "bebpreto",
    categories: ["Cobertura total"],
    video: "https://www.youtube.com/watch?v=20bnZ0EPffA",
    videoEmbed: "https://www.youtube.com/embed/20bnZ0EPffA",
  },
];
