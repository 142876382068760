import { useRef, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Icon,
  Typography,
  styled,
  Dialog,
  Button,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappIcon,
  TelegramIcon,
  FacebookIcon,
} from "react-share";
import { handleDarkColor } from "../../utils";

import "./styles.css";

import { DownloadButton } from "../../components/bula-button/DownloadButton";
import { getDownloadFileByRoute } from "../../utils/getDownloadFileByRoute";
import { useLocation } from "react-router-dom";

interface IShareButtonsProps {
  options?: Array<any> | undefined;
  color?: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const ShareButtons: React.FC<IShareButtonsProps> = ({
  options,
  color,
}) => {
  const theme = useTheme();
  const { spacing, breakpoints, palette } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  // msg copy link
  const [linkCopied, setLinkCopied] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const copyURL = () => {
    const currentURL = window.location.href;
    return currentURL;
  };

  const copyLink = () => {
    const currentURL = window.location.href;
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        setLinkCopied(true);

        setTimeout(() => {
          setLinkCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Erro ao copiar URL: ", err);
      });
  };

  return (
    <>
    <Box display="flex" alignItems="center" gap={1}>
      {getDownloadFileByRoute(pathname) && <DownloadButton color={color} />} 
      {smDown ? (
        <IconButton
          onClick={handleClickOpen}
          className="shareButton"
          sx={{
            background: color ?? palette.tertiary.main,
            "&:hover": {
              backgroundColor: color
                ? handleDarkColor(color, 20)
                : palette.tertiary.dark,
            },
          }}
        >
          <Icon sx={{ fontSize: 20 }}>share</Icon>
        </IconButton>
      ) : (
        <Button
          variant="contained"
          size="small"
          // color={color ? color : "tertiary"}
          sx={{
            borderRadius: "10px",
            fontSize: 12,
            background: color ?? palette.tertiary.main,
            "&:hover": {
              backgroundColor: color
                ? handleDarkColor(color, 20)
                : palette.tertiary.dark,
            },
          }}
          endIcon={<Icon sx={{ fontSize: 12 }}> share </Icon>}
          onClick={handleClickOpen}
        >
          compartilhar
        </Button>
      )}
      </Box>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontFamily: "Acherus Grotesque",
            fontWeight: "bold",
            textAlign: "center",
          }}
          id="customized-dialog-title"
        >
          Compartilhar
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <DialogContent
          dividers
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box px={4}>
            <Box mb={2} display="flex" flexWrap="wrap" justifyContent="center">
              <WhatsappShareButton url={copyURL()} style={{ margin: "5px" }}>
                <WhatsappIcon
                  size={45}
                  round
                  style={{ margin: "0 15px 0px" }}
                />
                <Typography
                  sx={{ fontFamily: "Acherus Grotesque", fontSize: "10px" }}
                >
                  Whatsapp
                </Typography>
              </WhatsappShareButton>

              <TelegramShareButton url={copyURL()} style={{ margin: "5px" }}>
                <TelegramIcon
                  size={45}
                  round
                  style={{ margin: "0 15px 0px" }}
                />
                <Typography
                  sx={{ fontFamily: "Acherus Grotesque", fontSize: "10px" }}
                >
                  Telegram
                </Typography>
              </TelegramShareButton>

              <FacebookMessengerShareButton
                url={copyURL()}
                style={{ margin: "5px" }}
                appId=""
              >
                <FacebookMessengerIcon
                  size={45}
                  round
                  style={{ margin: "0 15px 0px" }}
                />
                <Typography
                  sx={{ fontFamily: "Acherus Grotesque", fontSize: "10px" }}
                >
                  Messenger
                </Typography>
              </FacebookMessengerShareButton>

              <FacebookShareButton url={copyURL()} style={{ margin: "5px" }}>
                <FacebookIcon
                  size={45}
                  round
                  style={{ margin: "0 15px 0px" }}
                />
                <Typography
                  sx={{ fontFamily: "Acherus Grotesque", fontSize: "10px" }}
                >
                  Facebook
                </Typography>
              </FacebookShareButton>
            </Box>
            <Box position="relative">
              <Button
                variant="outlined"
                startIcon={<Icon sx={{ fontSize: 12 }}> link </Icon>}
                onClick={copyLink}
                fullWidth
              >
                copiar link
              </Button>
              {linkCopied && (
                <Typography
                  sx={{
                    fontFamily: "Acherus Grotesque",
                    fontSize: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: palette.primary.main,
                    position: "absolute",
                    bottom: "-15px",
                    left: "40%",
                  }}
                >
                  link copiado
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button
            sx={{ fontFamily: "Acherus Grotesque", fontWeight: "bold" }}
            autoFocus
            onClick={handleClose}
            variant="contained"
          >
            fechar
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  );
};
