import { createTheme, getContrastRatio } from "@mui/material";
import { cyan, yellow } from "@mui/material/colors";
import { handleDarkColor, handleLightColor } from "../utils";

export const DarkTheme = createTheme({
  palette: {
    primary: {
      main: yellow[700],
      dark: yellow[800],
      light: yellow[500],
      contrastText: "#ffffff",
    },
    secondary: {
      main: cyan[500],
      dark: cyan[400],
      light: cyan[300],
      contrastText: "#ffffff",
    },
    background: {
      paper: "#303134",
      default: "#202124",
    },
    text: {
      primary: "#305395",
      secondary: "#ffffff",
    },
    white: {
      main: "#ffffff",
      light: handleLightColor("#ffffff", 20),
      dark: handleDarkColor("#ffffff", 20),
      contrastText: getContrastRatio("#ffffff", "#fff") > 4.5 ? "#fff" : "#111",
    },

    dark: {
      main: "#344767",
      light: handleLightColor("#344767", 20),
      dark: handleDarkColor("#344767", 20),
      contrastText: getContrastRatio("#344767", "#fff") > 4.5 ? "#fff" : "#111",
    },

    transparent: {
      main: "rgba(0,0,0,0)",
      light: handleLightColor("rgba(0,0,0,0)", 20),
      dark: handleDarkColor("rgba(0,0,0,0)", 20),
      contrastText:
        getContrastRatio("rgba(0,0,0,0)", "#fff") > 4.5 ? "#fff" : "#111",
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});
