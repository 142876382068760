import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Footer, HeaderMenu, ShareButtons } from "../../shared/components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProducts } from "../../shared/services/products/producs";
import { ProductImg } from "./components/product-img/productImg";
import { ProductColor } from "./components/product-color/productColor";
import { CompositionCard } from "./components/composition-card/compositionCard";
import { ContentCard } from "./components/content-card/contentCard";
import { ProductSlider } from "./components/product-slider/productSlider";

import "./styles.css";

export const ProductsView: React.FC = () => {
  const theme = useTheme();
  const { breakpoints } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();
  const { path } = useParams<string>();
  const [initialDataFromApi, setInitialDataFromApi] = useState<any>();

  const [pageType, setPageType] = useState<any>();

  const loadProduct = () => {
    const product = getProducts(path as string);
    if (product) {
      setInitialDataFromApi(product);
      handleTypePage(path);
    } else navigate("/");
  };

  const handleTitle = (title: string) => {
    if (!title) return title;
    if (!title.includes("/n")) {
      return (
        <Typography
          variant={smDown ? "h4" : "h3"}
          sx={{ fontFamily: "Addington CF", fontWeight: 700 }}
        >
          {title}
        </Typography>
      );
    }
    const newTitle = title.split("/n");
    return (
      <>
        <Typography
          variant={smDown ? "h4" : "h3"}
          sx={{ fontFamily: "Addington CF", fontWeight: 700 }}
        >
          {newTitle[0]}
        </Typography>
        <Typography variant="h6" mb={2} sx={{ fontWeight: 700 }}>
          {newTitle[1]}
        </Typography>
      </>
    );
  };

  function handleTypePage(p: string | undefined) {
    if (p?.includes("grecin2000")) {
      setPageType({
        type: "grecin2000",
        background: {
          primary: "#B4C6E7",
          secondary: "#8EAADB",
        },
        composition_image: "",
      });
    } else if (p?.includes("tonsdegrisalho")) {
      setPageType({
        type: "tonsdegrisalho",
        background: {
          primary: "#3F72B2",
          secondary: "#183362",
        },
        composition_image: "",
      });
    } else if (p?.includes("shampoo")) {
      setPageType({
        type: "shampoo",
        background: {
          primary: "#E1433A",
          secondary: "#BF1D3E",
        },
        composition_image: "",
      });
    } else if (p?.includes("beb")) {
      setPageType({
        type: "beb",
        background: {
          primary: "#0BA7BC",
          secondary: "#00859A",
        },
        composition_image: "",
      });
    } else
      setPageType({
        type: "default",
        background: {
          primary: "#0BA7BC",
          secondary: "#00859A",
        },
        composition_image: "",
      });
  }

  useEffect(() => {
    loadProduct();
  }, [path]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <HeaderMenu />
      {initialDataFromApi && (
        <Divider
          sx={{ background: pageType?.background?.secondary, height: "16px" }}
        />
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={3}
        mb={2}
      >
        <Box maxWidth="1440px" width="100%" height="100%">
          {!smDown && (
            <Box px={2} mt={-2} display="flex" justifyContent="flex-end">
              <ShareButtons color={pageType?.background?.secondary} />
            </Box>
          )}
          <Grid container>
            <Grid item xs={10} sm={6} md={4} py={2} pl={10}>
              <Box className="productTitleContainer">
                {handleTitle(initialDataFromApi?.title)}
                <p>{initialDataFromApi?.subtitle}</p>
              </Box>
            </Grid>
            {smDown && (
              <Grid item xs={2} pr={2} pt={2}>
                <ShareButtons color={pageType?.background?.secondary} />
              </Grid>
            )}
            {initialDataFromApi && (
              <Grid item xs={12} sm={6} md={4.5}>
                <Box className="productImageContainer">
                  <ProductImg
                    url={initialDataFromApi?.featured_image}
                    categoryArray={initialDataFromApi?.categories ?? []}
                    color={pageType?.background?.primary}
                  />
                </Box>
              </Grid>
            )}
            {![1012, 1050].includes(initialDataFromApi?.id) && (
              <Grid item xs={12} sm={3} md={3.5} pl={2} py={2}>
                <ProductColor productId={initialDataFromApi?.id} />
              </Grid>
            )}
          </Grid>

          <Box my={6}>
            <CompositionCard
              productId={initialDataFromApi?.id}
              composition={initialDataFromApi?.composition}
              activator={initialDataFromApi?.activator_composition}
            />
          </Box>

          <Box my={6}>
            <ContentCard productId={initialDataFromApi?.id} />
          </Box>

          <Box mt={6} mb={25}>
            <ProductSlider
              imageList={initialDataFromApi?.images}
              productId={initialDataFromApi?.id}
            />
          </Box>

          <Box px={2} mb={12}>
            <Typography
              variant="h4"
              align="center"
              fontFamily="Addington CF"
              fontWeight={700}
            >
              Disponível em todo o Brasil
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
