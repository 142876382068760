import { useEffect, useState } from "react";

import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import bisnaga from "../../../../shared/assets/products/bisnaga_grecin.png";
import locao from "../../../../shared/assets/products/grecin2000_locao.png";
import shampoo_color from "../../../../shared/assets/products/shampoo_color.png";
import activator_shampoo_color from "../../../../shared/assets/products/activator_shampoo_color.png";
import grecin_base_colorante from "../../../../shared/assets/products/grecin_shampo_color_base_colorante.png";

import backgroundFallSmallGrecin2000 from "../../../../shared/assets/background_fall_small_grecin2000.svg";
import backgroundFallSmallTonsOfGray from "../../../../shared/assets/background_fall_small_tons_of_gray.svg";
import backgroundFallSmallShampoo from "../../../../shared/assets/background_fall_small_shampoo.svg";
import backgroundFallSmallBeard from "../../../../shared/assets/background_fall_small_beard.svg";

import "./styles.css";

interface ICompositionCardProps {
  productId: number;
  composition: string;
  activator?: string;
}

const grecin2000Configs = {
  divider_color: "#B4C6E7",
  svg_image: backgroundFallSmallGrecin2000,
  image_composition: locao,
  image_activator: locao,
};

const grecinTonsOfGrayConfigs = {
  divider_color: "#3F72B2",
  svg_image: backgroundFallSmallTonsOfGray,
  image_composition: "",
  image_activator: "",
};

const shampooConfigs = {
  divider_color: "#E1433A",
  svg_image: backgroundFallSmallShampoo,
  image_composition: grecin_base_colorante,
  image_activator: activator_shampoo_color,
};

const beardConfigs = {
  divider_color: "#0ba7bc",
  svg_image: backgroundFallSmallBeard,
  image_composition: bisnaga,
  image_activator: bisnaga,
};

export const CompositionCard: React.FC<ICompositionCardProps> = ({
  productId,
  composition,
  activator,
}) => {
  const theme = useTheme();
  const { breakpoints } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));
  const [cardConfigs, setCardConfigs] = useState<any>();

  const handleCardOptions = (id: number) => {
    switch (id) {
      case 1012:
        setCardConfigs(grecin2000Configs);
        break;

      case 1050:
        setCardConfigs(grecinTonsOfGrayConfigs);
        break;

      case 1111:
      case 1112:
      case 1113:
      case 1114:
      case 1116:
        setCardConfigs(shampooConfigs);
        break;

      case 3130:
      case 3131:
      case 3132:
      case 3133:
        setCardConfigs(beardConfigs);
        break;

      default:
        setCardConfigs(beardConfigs);
        break;
    }
  };

  useEffect(() => {
    handleCardOptions(productId);
  }, [productId]);

  return (
    <>
      <Box px={2} mb={10}>
        <Typography
          variant="h4"
          align="center"
          fontFamily="Addington CF"
          fontWeight="700"
        >
          Ingredientes poderosos, resultados íncriveis
        </Typography>
      </Box>
      <Box
        className="compositionCardContainer"
        sx={{
          background: `url(${cardConfigs?.svg_image})`,
        }}
      >
        <Box sx={{ position: "relative", top: -20 }}>
          <Grid container spacing={2} height="100%" justifyContent="center">
            <Grid item xs={12} sm={!activator ? 11 : 7.5}>
              <Paper elevation={3} className="paperComposition">
                <Box p={2} display="flex">
                  <Box
                    className="paperCompositionBox"
                    sx={{
                      border: `1px solid ${cardConfigs?.divider_color}`,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={32}
                        color={cardConfigs?.divider_color}
                        fontFamily="Addington CF"
                      >
                        {![1012, 1050, 1418, 1420].includes(productId)
                          ? "Composição Base Colorante"
                          : "Composição (Português)"}
                      </Typography>
                      <Typography fontSize={16}>{composition}</Typography>
                    </Box>
                    {cardConfigs?.image_composition && (
                      <Box className="paperImageComposition">
                        <img
                          src={cardConfigs?.image_composition}
                          alt="bisnaga"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {activator && (
              <Grid item xs={12} sm={4} className="paperCompositionActivator">
                <Paper elevation={3} className="paperComposition">
                  <Box p={2} display="flex">
                    <Box className="paperCompositionBox ">
                      {smDown && (
                        <Box className="paperImageActivatorResponsive">
                          <img
                            src={cardConfigs?.image_activator}
                            alt="bisnaga"
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography
                          variant="h4"
                          fontWeight={700}
                          fontSize={32}
                          color={cardConfigs?.divider_color}
                          fontFamily="Addington CF"
                        >
                          Ativador
                        </Typography>
                        <Typography fontSize={16}>{activator}</Typography>
                      </Box>
                      {!smDown && (
                        <Box className="paperImageActivator">
                          <img
                            src={cardConfigs?.image_activator}
                            alt="bisnaga"
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Divider
        sx={{ background: cardConfigs?.divider_color }}
        className="divider"
      />
    </>
  );
};
