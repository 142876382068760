
const pdfMap: { [key: string]: { fileUrl: string; fileName: string } } = {
    "/tonsdegrisalho": {
      fileUrl: "/bulas-pdf/TOG-BRZ-0004_1050_Leaflet_v5.pdf",
      fileName: "TOG-BRZ-0004_1050_Leaflet_v5.pdf",
    },
    "/shampoocastanhoclaro": {
      fileUrl: "/bulas-pdf/JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
      fileName: "JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
    },
    "/shampoocastanho": {
      fileUrl: "/bulas-pdf/JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
      fileName: "JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
    },
    "/shampoocastescuro": {
      fileUrl: "/bulas-pdf/JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
      fileName: "JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
    },
    "/shampoopreto": {
      fileUrl: "/bulas-pdf/JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
      fileName: "JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
    },
    "/shampoocastmedescuro": {
      fileUrl: "/bulas-pdf/JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
      fileName: "JOF-BRZ-0009_1111_1116_Leaflet_v3.pdf",
    },
    "/bebcastclaro": {
      fileUrl: "/bulas-pdf/JMB-BRZ-0011_3130_3133_Leaflet.pdf",
      fileName: "JMB-BRZ-0011_3130_3133_Leaflet.pdf",
    },
    "/bebcastanho": {
      fileUrl: "/bulas-pdf/JMB-BRZ-0011_3130_3133_Leaflet.pdf",
      fileName: "JMB-BRZ-0011_3130_3133_Leaflet.pdf",
    },
    "/bebcastescuro": {
      fileUrl: "/bulas-pdf/JMB-BRZ-0011_3130_3133_Leaflet.pdf",
      fileName: "JMB-BRZ-0011_3130_3133_Leaflet.pdf",
    },
    "/bebpreto": {
      fileUrl: "/bulas-pdf/JMB-BRZ-0011_3130_3133_Leaflet.pdf",
      fileName: "JMB-BRZ-0011_3130_3133_Leaflet.pdf",
    },
  };
export const getDownloadFileByRoute = (route: string) => {
  return pdfMap[route] ?? null;
}