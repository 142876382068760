// import { createTheme, alpha } from "@mui/material";
// import { cyan, yellow } from "@mui/material/colors";

import { createTheme, getContrastRatio } from "@mui/material/styles";
import { handleDarkColor, handleLightColor } from "../utils";

export const primary = "#2D2926";
export const secondary = "#315496";
export const tertiary = "#00859A";

export const LightTheme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: handleLightColor(primary, 20),
      dark: handleDarkColor(primary, 20),
      contrastText: getContrastRatio(primary, "#fff") > 4.5 ? "#fff" : "#111",
    },
    secondary: {
      main: secondary,
      light: handleLightColor(secondary, 20),
      dark: handleDarkColor(secondary, 20),
      contrastText: getContrastRatio(secondary, "#fff") > 4.5 ? "#fff" : "#111",
    },

    tertiary: {
      main: tertiary,
      light: handleLightColor(tertiary, 20),
      dark: handleDarkColor(tertiary, 20),
      contrastText: getContrastRatio(tertiary, "#fff") > 3.5 ? "#fff" : "#111",
    },

    background: {
      paper: "#ffffff",
      default: "#f1f1f1",
      // default: "#f7f6f3",
    },
    text: {
      primary: "#2D2926",
      secondary: "#305395",
    },

    white: {
      main: "#ffffff",
      light: handleLightColor("#ffffff", 20),
      dark: handleDarkColor("#ffffff", 20),
      contrastText: getContrastRatio("#ffffff", "#fff") > 4.5 ? "#fff" : "#111",
    },

    dark: {
      main: "#344767",
      light: handleLightColor("#344767", 20),
      dark: handleDarkColor("#344767", 20),
      contrastText: getContrastRatio("#344767", "#fff") > 4.5 ? "#fff" : "#111",
    },

    transparent: {
      main: "rgba(0,0,0,0)",
      light: handleLightColor("rgba(0,0,0,0)", 20),
      dark: handleDarkColor("rgba(0,0,0,0)", 20),
      contrastText:
        getContrastRatio("rgba(0,0,0,0)", "#fff") > 4.5 ? "#fff" : "#111",
    },
  },
  typography: {
    fontFamily: [
      "Acherus Grotesque",
      "Addington CF",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});
