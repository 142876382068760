import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";
import { Box, Divider, styled, useMediaQuery, useTheme } from "@mui/material";

interface ISlideProps {
  slides: Array<string>;
  color?: string;
  dividerColor?: string;
}

export const SliderCenter: React.FC<ISlideProps> = ({
  slides,
  dividerColor,
  color,
}) => {
  const theme = useTheme();
  const { breakpoints } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));

  const [activeSlide, setActiveSlide] = useState<any>(0);
  const [paginationColor, setPaginationColor] = useState<any>();

  const params = {
    slidesPerView: smDown ? 1 : 2,
    centeredSlides: true,
    spaceBetween: 5,
    loop: false,
    // pagination: {
    //   el: ".swiper-center-pagination",
    //   clickable: true,
    //   // renderBullet: function (index: number, className: string) {},
    // },
    navigation: {
      nextEl: ".swiper-center-button-next",
      prevEl: ".swiper-center-button-prev",
    },
    onSlideChange: (e: any) => handleSlideChange(e.activeIndex),
    modules: [Pagination, Navigation],
    style: {
      paddingBottom: slides.length > 1 ? "40px" : "0",
    },
  };

  const handleSlideChange = (swiper: any) => {
    setActiveSlide(swiper);
  };

  function changeColors() {
    const elements: any = document.getElementsByClassName(
      "swiper-pagination-bullet"
    );
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = color; // Nova cor desejada
    }
  }

  useEffect(() => {
    if (color) {
      changeColors();
      setPaginationColor(color);
    }
  }, [color]);

  return (
    <>
      <Swiper
        {...params}
        className="swiper-center"
        pagination={{
          el: ".swiper-center-pagination",
          clickable: true,
          renderBullet: (index: number, className: string) => {
            var elementos = document.getElementsByClassName(
              "swiper-pagination-bullet-active"
            );
            return `<span class="${className}" style="background-color:${
              paginationColor ? paginationColor : "#00859a"
            };">
            </span>`;
          },
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="swiper-center-slide">
            <img alt="Produtos Grecin" src={slide} />
          </SwiperSlide>
        ))}

        <Box sx={{ display: slides.length > 1 ? "block" : "none" }}>
          <Box
            className="swiper-button-prev swiper-center-button-prev"
            sx={{ backgroundColor: color }}
          ></Box>
          <Box
            className="swiper-button-next swiper-center-button-next"
            sx={{ backgroundColor: color }}
          ></Box>
        </Box>

        <Box className="swiper-pagination swiper-center-pagination"></Box>
      </Swiper>
      {dividerColor && (
        <Divider color={dividerColor} sx={{ height: theme.spacing(1) }} />
      )}
    </>
  );
};
